<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="11"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="11" ></LeftNav>
                <div class="flex-grow-1">
                    <div class="ml20 pt30 plr30 personal basic_info">
                        <div class="fs20 fwb pb20">{{$t('changeemail')}} </div>
                        <div class="flex-box flex-col-start">
                            <div>
                                <template v-if="!issend">
                                    <div class="fs16 col5 pb5">{{$t('transcation')}}</div>
                                    <el-input class="input mb30" placeholder="enter old transcation" type="password" v-model="transcation"></el-input>
                                    <div class="fs16 col5 pb5">{{$t('newemail')}}</div>
                                    <el-input class="input mb30" placeholder="enter new email"  v-model="email"></el-input>
                                </template>
                                <template v-else>
                                    <div class="fs16 col5 pb5">{{$t('code')}}</div>
                                    <el-input class="input mb30" placeholder="enter code"  v-model="code"></el-input>
                                </template>
                            </div>
                        </div>

                        <div class="flex-box flex-col-start" >
                            <a href="javascript:;" v-if="!issend" class="btn fs16 colf confirm" @click="send">{{$t('send')}}</a>
                            <a href="javascript:;" v-else class="btn fs16 colf confirm" @click="confirm">{{$t('sure')}}</a>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import cookie from "vue-cookies";
import axios from "axios";

export default {
    data() {
        return {
            issend:false,
            transcation:'',
            email:'',
            code:''
        };
    },
    created(){
        this.getuser()
    },
    methods: {
        getuser(){
            this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){
					this.user = ret.data.userinfo;
				}
			})
        },
        send(){
            if(!this.transcation){
                return this.$message({
                    message: '请填写交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
            }
            if(!this.email){
                return this.$message({
                    message: '请填写新邮箱',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                
            }
            const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;

            if (!regEmail.test(this.email)) {
                // 合法的邮箱
                // return this.$message.error("邮箱格式错误");
                return this.$message({
                    message: "邮箱格式错误",
                    type: 'error',
                    offset: window.screen.height / 2
                })
            }
            this.$loading({
                lock: true, // 是否禁止背景滚动，默认为false
                text: 'Loading', // 加载文本提示，默认为'Loading'
                spinner: 'el-icon-loading', // 加载图标类型，默认为'el-icon-loading'
                background: 'rgba(0, 0, 0, 0.7)' // 遮罩层背景颜色，默认为'rgba(0, 0, 0, 0.7)'
            })
            this.$api.apiSendcode({ password:this.transcation,email: this.email}).then((res) => {
                console.log(res)
                this.$loading().close()
                if (res.code == 1) {
                    this.issend = true
                    this.$message({
                        message: "发送成功",
                        type: 'success',
                        offset: window.screen.height / 2
                    })
                } else {
                    this.issend = false
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })

                }
            })
        },
        confirm(){
            if (!this.code) {
                return this.$message({
                    message: '请填写验证码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            
            var data={email:this.email,captcha:this.code}
            this.$api.changeemail(data).then(ret=>{
                if(ret.code==1){
                    // this.$message.success(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                    // localStorage.removeItem("sma_token");
                    // cookie.remove("sma_token");
                    // cookie.remove("sma_userinfo");
                    window.location.reload(true);
                }else{
                    // this.$message.error(ret.msg)
                    this.$message({
                    message: ret.msg,
                    type: 'error',
                    offset: window.screen.height / 2
                })
                }
            })
        },
        saveconfirm2(){
            if (!this.oldpassword) {
                return this.$message({
                    message: '请填写当前交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            if (!this.pwd) {
                return this.$message({
                    message: '请填写新交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写登陆密码')
            }
            if (!this.pwd2) {
                return this.$message({
                    message: '请填写确认交易密码',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('请填写确认密码')
            }
            if (this.pwd != this.pwd2) {
                return this.$message({
                    message: '两次密码不同',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // return this.$message.error('两次密码不同')

            }
            var data={pwd:this.pwd,oldpassword:this.oldpwd}
            this.$api.apiPostPassword(data).then(ret=>{
                if(ret.code==1){
                    this.pwd=''
                    this.oldpwd=""
                    this.pwd2=""
                    // this.$message.success(ret.msg);
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                }else{
                    // this.$message.error(ret.msg);
                    this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        tpwd(){
            if(!this.pwd){
                // return this.$message.error('密码不可为空')
                return this.$message({
                    message: '密码不可为空',
                    type: 'error',
                    offset: window.screen.height / 2
                })
            }
            this.$api.apiPostPassword({pwd:this.pwd}).then(ret=>{
                if(ret.code==1){
                    // this.$message.success(ret.msg);
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                    this.pwd=''
                }else{
                    // this.$message.error(ret.msg);
                    this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        }
    }
};
</script>
